<template>
  <div class="card-container">
    <h4 v-if="group.display_name">
      {{ group.display_name }}
    </h4>
    <h4 v-else class="placeholder">
      {{ $t('words.new') }}
    </h4>
    <b-avatar src="https://originalps.com/wp-content/uploads/2017/09/picto-groupe.jpg" size="7rem"></b-avatar>
  </div>
</template>

<script>
export default {
  name: 'GroupCard',

  props: {
    group: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style scoped>
h4 {
  margin-bottom: 1rem !important;
}
.placeholder {
  color: rgb(196, 196, 196);
}
</style>
